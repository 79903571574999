<template>
  <div class="col-12 g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <div class="col-12 text-center">
                <button class="btn btn-primary" @click="print()">
                    <i class="fa fa-print"></i>
                    طباعة الطلاب المحددين
                </button>
            </div>
            <div class="form-group">
              <h5 for="">بحث</h5>
              <input type="text"
                class="form-control" v-model="q" placeholder="ابحث هنا..." @keyup="getStudents()" @change="getStudents()">
            </div>
            <table class="table table-sm table-hover table-bordered table-striped">
                <thead>
                    <th>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="all" @click="selecteall()" value="1">
                            تحديد الكل
                          </label>
                        </div>
                    </th>
                    <th>
                        الهوية
                    </th>
                    <th>
                        الجوال
                    </th>
                </thead>
                <tbody>
                    <tr v-for="student in students" :key="student._id">
                        <td>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" v-model="selected" :value="student.number">
                                {{ student.name }}
                              </label>
                            </div>
                        </td>
                        <td>
                            {{ student.number }}
                        </td>
                        <td>
                            {{ student.phone }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
    data(){
        return {
            students: [],
            loading: false,
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            username: JSON.parse(localStorage.getItem("user")).name,
            q: null,
            selected: [],
            original_students: [],
            all: false,
        }
    },
    created(){
        var g = this;
        g.getStudents()
    },
    methods: {
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.jwt,
                q: g.q
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    g.original_students = r.response
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        print(){
            var g = this;
            var win = window.open("", "")
            var data = ""
            g.original_students.forEach(element => {
                if(g.selected.includes(element.number)){
                    data = data + `
                        <div style='height: 14.2857143vh; width: 33.33333%; padding: 10px;'>
                            <div style="border: 1px dashed #999; height: 100%; padding: 10px;">
                                <center>
                                ${g.username}<br>
                                <h3>${element.name}</h3><br><img src='https://barcodeapi.org/api/auto/${element.number}' width='200' style='max-height: 148px !important'></center>
                            </div>
                        </div>
                    `
                }
            });
                win.document.write(`
                <html>
                <head>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.1/css/bootstrap.min.css"/>
                </head>
                    <body>
                        <div class="container-fluid">
                            <div class='row'>
                                ${data}
                            </div>
                        </div>
                    </body>
                </html>
                `)
                setTimeout(() => {
                    win.print()
                }, 3000);
        },
        selecteall(){
            if(!this.all){
                this.selected = this.students.map(function(c){
                    return c.number
                })
            }else{
                this.selected = []
            }
        }
    }
}
</script>

<style>

</style>